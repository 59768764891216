@use '@styles/_partials' as *;
.BuilderImage img {
  width: 100%;
  height: auto;
}

// MT-68: The way we are using styles here is to make specific images work for Hero component used as a promo image component
// TODO: There should be a specific promo image component and these styles should really only apply to mobile rendered images
.mobileImage > img {
  @include respond-to-max-width($lg) {
    margin-top: 40px;
    height: initial !important;
    object-fit: initial !important;
  }
}
